import React from "react";
// import { Link } from "gatsby";
import Layout from "../components/layout";
import { StaticImage } from 'gatsby-plugin-image';
import Seo from '../components/seo';


const Daria = () => {
    return (
        <Layout>
            <Seo title="Daria Kyiko"/>
            <section className="member-container">
                <section className="member-intro">
                    <div className="member-intro_text">
                        <h1 className="member-intro_text_name">Daria Kyiko</h1>
                        <p className="member-intro_text_description">Instruktorka Pilates
                        </p>
                    </div>
                    <div className="member-intro_picture-container">
                        <StaticImage 
                            src='../images/poza_daria.jpg' 
                            placeholder="blurred"
                            loading="eager"
                            alt="instruktorka Daria Kyiko" 
                            className='member-intro_picture'
                        />
                    </div>
                </section>
                <section className="member-hero">
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">O DARII</h2>
                        </div>
                        <div className="member-hero-paragraph_info">
                            <h3 className="member-hero-paragraph_info-bold">Instruktorka Pilatesu, studentka międzynarodowej Szkoły Pilatesu POLESTAR
                            </h3>
                            <br/>
                            <p className="member-hero-paragraph_info-text">Daria przyjechała do Polski z Ukrainy w 2022 r. na skutek wybuchu wojny. 
                            Na Ukrainie przez wiele lat była stomatologiem. Kilka lat temu zaczęła się interesować postawą ciała w kontekście wad zgryzu i te poszukiwania zaprowadziły ją na nową ścieżkę zawodową - chciała zostać nauczycielem metody Pilates.
                            Rozpoczęła intensywny kurs Pilatesu na maszynach w międzynarodowej szkole "POLESTAR", ale wojna pokrzyżowała jej na jakiś czas plany. Na szczęście znalazła się w Polsce, skąd może kontynuować wyjazdy do Belgii, gdzie bierze udział w tym profesjonalnym kursie.
                            </p>
                            <p className="member-hero-paragraph_info-text">Daria jest jeszcze przed egzaminem dyplomowym w pracy metodą Pilates na wszystkich sprzętach, ale po kilkuletnim kursie jest już gotowa, by prowadzić zajęcia certyfikowane metodą Pilates na Reformerze, Cadillacu, Wunda Chair, Spine Correctorze i na macie.</p>    
                            <p className="member-hero-paragraph_info-text">Z wykształcenia dentysta.</p>
                        </div>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">JĘZYKI<br/>LANGUAGES</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Angielski/ English</li>
                            <li>Ukraiński/ Ukrainian</li>
                            <li>Polski/ Polish</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">METODY</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Pilates</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">RODZAJE ZAJĘĆ</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Zajęcia indywidualne</li>
                                <li>Zajęcia w duetach</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">RODZAJE SPRZĘTU</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Reformer</li>
                            <li>Reformer z Wieżą</li>
                            <li>Wunda Chair oraz Combo Chair</li>
                            <li>Cadillac</li>
                            <li>Spine Corrector</li>
                            <li>Mata oraz sprzęt pilatesowy typu : Roller, Disc, Small Ball, Heavy Ball, Ovo Ball, Body Ball, Band, Magic Circle oraz dodatkowe akcesoria sportowe, np. małe ciężarki, piłeczki do automasażu, itp.</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">SPECJALIZACJE</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Sportowcy</li>
                            <li>Osoby z wadami postawy</li>
                            <li>Osoby z dolegliwościami bólowymi w układzie ruchu</li>
                            <li>Kobiety po porodzie</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title"></div>
                        <h4 className="member-hero-paragraph_info-bold">Zapisy na zajęcia z Darią tylko mailowo</h4>
                        <div className="member-hero-paragraph_info_link-container">
                            <a href="mailto:daykiri88@gmail.com" className="member-hero-paragraph_info_link-link">ZAPISZ SIĘ</a>
                        </div>
                    </section>
                </section>
            </section>
        </Layout>
    )
}

export default Daria;